import './app';
import './bootstrapService';
import './languageLoader';
import './interceptors/gravityInterceptor';
import './interceptors/sevenInterceptor';
import './interceptors/tokenInterceptor';
import './deviceDataLoaders/localStorageLoader';
import './deviceDataLoaders/narDeviceLoader';
import './deviceDataLoaders/sevenDeviceProfileLoader';
import './deviceDataLoaders/dmDeviceLoader';
import './environment';
import './vendorConfig';
import './iframeBootstrapService';

export default '7Shop.Bootstrap';
