import angular from 'angular';
import DeviceManagementCoreService from './deviceManagementCoreService';
import run from './run';

const deviceManagementModule = angular.module('7Shop.DeviceManagement', [])
  .service('DeviceManagementCoreService', DeviceManagementCoreService)
  .run(run)
  .name;

export default deviceManagementModule;
