(function () {
  angular.module('7Shop.Core')
    .directive('sAutoFocus', function sAutoFocus() {
      return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
          if (attrs.sAutoFocus === 'true') {
            elem.trigger('focus');
          }
        }
      };
    });
})();
