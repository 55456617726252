/**
 * @class ShopPrintService
 * @memberof module:"7Shop.Print"
 */
/* @ngInject */
function ShopPrintService(
  $window,
  Modules,
  PeripheralsSvc,
  Widgets,
) {
  const additionalPrintData = {};
  const printServiceTypes = {
    nar: 'nar_print',
    dm: 'dm_print'
  };
  const printServiceState = {
    ready: false,
    type: '', // 'nps' or 'sps' when printService is narPrintService, otherwise it will be 'dm'
    status: {}
  };
  let printService = null;

  function init() {
    return detectPrintService().then(function (type) {
      return Modules.loadModule(printServiceTypes[type]);
    });
  }

  function detectPrintService() {
    const urlParams = new URLSearchParams($window.location.search);
    const ar = urlParams.get('ar') || '';

    return Promise.resolve(ar === 'dm' ? 'dm' : 'nar');
  }

  /**
   * Get print service
   * @returns {Object}
   * @memberOf ShopPrintService
   */
  function getPrintService() {
    return printService;
  }

  /**
   * Set print service
   * @param {Object} data
   * @memberOf ShopPrintService
   */
  function setPrintService(data) {
    printService = data;
    printServiceState.status = PeripheralsSvc.getPeripheral('PRINTER');
    Widgets.registerWidget({
      name: 'sPrinterStatus',
      source: [{
        type: 'ANGULARJS_COMPONENT',
        element: {
          tag: '<s-printer-status></s-printer-status>'
        }
      }],
      positions: [
        {
          id: 'footerRight'
        }
      ]
    });
  }

  /**
   * @param {Object} info
   * @param {String} info.type
   * @param {String} info.action
   * @param {String} info.productId
   * @param {String} info.layoutName
   * @param {String} info.layout
   * @param {Object} data
   * @param {Object} additionalData
   * @param {String} printerId
   * @memberOf ShopPrintService
   */
  function printJob(info, data, additionalData, printerId) {
    const mergedAdditionalData = {
      ...additionalPrintData,
      ...additionalData
    };
    return getPrintService().printJob(info, data, mergedAdditionalData, printerId);
  }

  /**
   * @param {Object} params
   */
  function getPrinterStatus(params) {
    return getPrintService().getPrinterStatus(params);
  }

  // this fn will be removed once printSettingsComponent gets splitted
  // into two separate components (dm & nar)
  function parseSupportedPrintersResponse(response) {
    const urlParams = new URLSearchParams($window.location.search);
    const ar = urlParams.get('ar');

    if (ar === 'dm') {
      return response.printers.map((item) => item.printer);
    }

    return response.data.printers || response.data.Printers;
  }

  function getSupportedPrinters() {
    return getPrintService().getSupportedPrinters();
  }

  function printA4(url) {
    const options = '_blank';
    const params = 'fullscreen=yes,scrollbars=yes,resizable=yes,status=yes,location=yes,toolbar=yes,'
        + 'menubar=yes,width=0,height=0,left=-1000,top=-1000';
    $window.open(url, options, params);
  }

  /**
   * @param {string} key
   * @param {*} value
   */
  function appendAdditionalPrintData(key, value) {
    additionalPrintData[key] = value;
  }

  return {
    init,
    setPrintService,
    getPrintService,
    detectPrintService,
    printServiceState,
    printJob,
    getPrinterStatus,
    getSupportedPrinters,
    printA4,
    appendAdditionalPrintData,
    parseSupportedPrintersResponse
  };
}

export default ShopPrintService;
