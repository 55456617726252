/* @ngInject */
export default function ($provide) {
  // keep decorator here for now till
  // we move nabPrintService to lazy load with integrations/nps
  $provide.decorator(
    'printServiceApi',
    function printServiceApiDecorator(
      $q,
      $delegate,
      userService,
      PeripheralsSvc,
      GravitySettings
    ) {
      /**
       *
       * @param {Object} config
       * @param {Object} config.selectedPrinter
       */
      $delegate.init = function (config) {
        return $q(function (resolve) {
          const cmsPrintTemplate = GravitySettings.getModuleDataKeyValue('config', 'printTemplate');
          const lsPrintTemplate = localStorage.getItem('settings.printTemplate');
          const loginData = userService.getData();
          let storedPrintTemplate;

          if (lsPrintTemplate) {
            const parsedTemplate = JSON.parse(lsPrintTemplate);
            storedPrintTemplate = parsedTemplate.template;
          }

          $delegate.initializeService(
            config.selectedPrinter,
            loginData.user.language
          ).finally(function () {
            PeripheralsSvc.updatePeripheralStatus('PRINTER', {
              valid: $delegate.current.Status.valid,
              statusLevel: $delegate.current.Status.code,
              errorMessage: $delegate.current.Status.message
            });
          });

          if (storedPrintTemplate || cmsPrintTemplate) {
            $delegate.updatePrintTemplate(storedPrintTemplate || cmsPrintTemplate);
          }

          resolve();
        });
      };

      return $delegate;
    }
  );
}
