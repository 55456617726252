import { Core } from '@nsftx/systems-sdk';
import * as deviceManagementInitService from '../../src/modules/device_management/deviceManagementInitService';

const LOG_PREFIX = '[7Shop.DeviceManagement]';

(function () {
  /* eslint-disable no-multi-assign */
  const ShopDeviceDataLoaders = window.ShopDeviceDataLoaders = window.ShopDeviceDataLoaders || {};
  const ngInjector = angular.injector(['ng']);
  const $log = ngInjector.get('$log');
  const $window = ngInjector.get('$window');

  ShopDeviceDataLoaders.dmDeviceLoader = {

    isInstalled: () => Promise.resolve(),

    getData: () => new Promise((resolve, reject) => {
      const core = new Core('7Shop');

      core.connect().catch((err) => {
        const message = 'Connection to DM Core has failed on app init.';
        const code = 'S_DEVICE_MANAGEMENT_CORE_INIT_CONNECTION_ERROR';
        const error = new Error(message, { cause: err });
        error.name = code;

        $log.error(`${LOG_PREFIX} ${message}`, { code });

        reject(err);
      });

      core.on('connection', () => {
        $log.info(`${LOG_PREFIX} Connection to DM Core was successful on app init.`, {
          code: 'S_DEVICE_MANAGEMENT_CORE_INIT_CONNECTION_SUCCESS',
          core
        });

        // expose Core instance so we can use it later in DM core service as a value of core
        // and will be called as getCore() later in print e.g
        $window.DM = $window.DM || {};
        $window.DM.Core = core;

        deviceManagementInitService.getData(core)
          .then(resolve)
          .catch(reject);
      });
    })
  };
})();
