import { Display } from '@nsftx/systems-sdk';

// This service is one-use based and we need to have it as a vanilla JS service not AngularJS service
// So we can use it on boot at phase where no angularjs modules are ready yet

const LOG_PREFIX = '[7Shop.DeviceManagement]';

const ngInjector = angular.injector(['ng']);
const $log = ngInjector.get('$log');
const $http = ngInjector.get('$http');
const $timeout = ngInjector.get('$timeout');

/**
   * Retrieves the application configuration URL
   *
   * @param {Core} core
   * @return {Promise<string>}
   */
const getApplicationConfigUrl = (core) => {
  // eslint-disable-next-line angular/window-service
  const urlParams = new URLSearchParams(window.location.search);
  const appRunUuid = urlParams.get('appRunUuid') || '';
  const display = new Display(core);

  $log.debug(`${LOG_PREFIX} Getting application config url`, {
    appRunUuid,
    code: 'S_DEVICE_MANAGEMENT_APP_DATA_CONFIG_URL_FETCH_PENDING'
  });

  return display.getApplication(appRunUuid);
};

/**
   * Retrieves the application configuration data from API
   *
   * @param {configUrl} string
   * @return {Promise<void>}
   */
const getApplicationConfigData = (configUrl) => {
  const username = process.env.DM_APPLICATION_CONFIG_BASIC_AUTH_USERNAME;
  const password = process.env.DM_APPLICATION_CONFIG_BASIC_AUTH_PASSWORD;

  return $http.get(configUrl, {
    headers: {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`
    }
  });
};

/**
   * Retrieves the application configuration
   *
   * @param {Core} core
   * @return {Promise<void>}
   */
const getApplicationConfig = (core) => new Promise(
  (resolve, reject) => {
    getApplicationConfigUrl(core)
      .then((data) => {
        const parsedData = JSON.parse(data);

        if (!parsedData.config_url) {
          const message = 'Application config url is empty';
          const code = 'S_DEVICE_MANAGEMENT_APP_DATA_CONFIG_URL_EMPTY';
          const error = new Error(message);
          error.name = code;

          $log.error(`${LOG_PREFIX} ${message}`, { code, data });
          reject(error);
        }

        getApplicationConfigData(parsedData.config_url)
          .then((res) => {
            resolve(res.data);
          }).catch((err) => {
            const message = 'Fetching application config data has failed';
            const code = 'S_DEVICE_MANAGEMENT_APP_DATA_CONFIG_FETCH_ERROR';
            const error = new Error(message, { cause: err });
            error.name = code;

            $log.error(`${LOG_PREFIX} ${message}`, {
              code
            });
            reject(error);
          });
      })
      .catch((err) => {
        const message = 'Fetching application config url has failed';
        const code = 'S_DEVICE_MANAGEMENT_APP_DATA_CONFIG_URL_FETCH_ERROR';
        const error = new Error(message, { cause: err });
        error.name = code;

        $log.error(`${LOG_PREFIX} ${message}`, {
          code
        });
        reject(error);
      });
  },
);

/**
   * Maps data from DM and makes it ready to be used on bootstrap init
   *
   * @param {Object} dmAppConfigData
   * @param {Object} dmDeviceData
   */
const mapDataFromDMToBootstrap = (dmAppConfigData, dmDeviceData) => ({
  device: {
    data: {
      device: {
        token: dmAppConfigData.device_token,
        deviceId: dmDeviceData.device.device_id,
        name: dmDeviceData.device.name,
        uuid: dmDeviceData.device.uuid
      },
      betshop: {
        displayId: dmAppConfigData.location_display_id,
        uuid: dmDeviceData.location.uuid,
        betshopId: dmDeviceData.location.betshop_id,
        name: dmDeviceData.location.name,
        companyId: dmDeviceData.space.company_id,
        companyName: dmDeviceData.space.name,
        companyUuid: dmDeviceData.space.uuid
      }
    }
  },
  appConfig: {
    data: {
      sevenUrl: dmAppConfigData.seven_url,
      sevenSocketUrl: dmAppConfigData.seven_socket_url
    }
  }
});

/**
   * Maps DM data to be used on bootstrap
   *
   * @param {Core} core
   * @returns Promise<void>
   */
const getData = (core) => new Promise((resolve, reject) => {
  const GET_DATA_TIMEOUT_IN_SECONDS = 10;

  const getDataTimer = $timeout(() => {
    const message = 'Fetching data from DM runtime has failed';
    const code = 'S_DEVICE_MANAGEMENT_APP_DATA_FETCH_ERROR';
    const error = new Error(message);
    error.name = code;

    $log.error(`${LOG_PREFIX} ${message}`, { code });
    reject(error);
  }, GET_DATA_TIMEOUT_IN_SECONDS * 1000);

  Promise.all([
    getApplicationConfig(core),
    core.deviceState()
  ])
    .then((data) => {
      const [appConfigData, deviceStateData] = data;

      $log.info(`${LOG_PREFIX} Device data has been successfully fetched from DM runtime`, {
        data,
        code: 'S_DEVICE_MANAGEMENT_APP_DATA_FETCH_SUCCESS'
      });

      const mappedData = mapDataFromDMToBootstrap(appConfigData, deviceStateData);

      resolve(mappedData);
    })
    .catch((err) => {
      reject(err);
    })
    .finally(() => $timeout.cancel(getDataTimer));
});

export {
  getApplicationConfigUrl,
  getApplicationConfig,
  getData,
  mapDataFromDMToBootstrap
};
