/**
 *
 * @class PrintListeners
 * @memberOf module:"7Shop.Print"
 */
/* @ngInject */
function PrintListeners(
  $rootScope,
  $log,
  PeripheralsSvc,
  NabNotifications,
  ShopPrintService
) {
  return {
    /**
     * @memberOf PrintListeners
     */
    setPrinterListeners: function () {
      /**
       * @event "7S:Peripherals.Print"
       * @memberOf module:"7Shop.Print".PrintListeners
       * @type {Object}
       *
       * @property {Object} data
       * @property {String} data.type - e.g. ticket
       * @property {String} data.action - e.g. Cancel
       * @property {String} data.productId
       * @property {Object} data.data - e.g. ticket object
       * @property {Object} data.additionalData
       */
      $rootScope.$on(
        '7S:Peripherals.Print',
        /**
         *
         * @param event
         * @param {Object} eventData
         * @param {Object} eventData.data
         * @param {String} eventData.data.type
         * @param {String} eventData.data.action
         * @param {String} eventData.data.productId
         * @param {Object} eventData.data.data
         * @param {Object} eventData.data.additionalData
         * @param {String} eventData.data.npsJobName
         * @param {String} eventData.data.layoutName
         * @param {String} eventData.data.layout
         */
        function (event, eventData) {
          ShopPrintService.printJob(
            {
              type: eventData.data.type,
              action: eventData.data.action,
              productId: eventData.data.productId,
              npsJobName: eventData.data.npsJobName,
              layoutName: eventData.data.layoutName,
              layout: eventData.data.layout
            },
            eventData.data.data,
            eventData.data.additionalData
          ).catch((err) => {
            $log.error(`[7Shop.Print] Failed to print on Peripherals.Print event. ${err.message}`, {
              action: eventData.data.action,
              product_displayid: eventData.data.productId,
              upstream_message: err.upstream_message,
              upstream_code: err.upstream_code,
              code: 'S_PERIPHERALS_PRINT_ERROR'
            });
            NabNotifications.show({
              message: err.message,
              type: 'warning',
              delay: 3000
            });
          });
        }
      );

      /**
       * @event "7S:Peripherals.PrintA4"
       * @memberOf module:"7Shop.Print".PrintListeners
       * @property {Object} data
       * @property {String} data.documentUrl
       */
      $rootScope.$on(
        '7S:Peripherals.PrintA4',
        /**
         *
         * @param event
         * @param {Object} eventData
         * @param {String} eventData.data.documentUrl.
         */
        function (event, eventData) {
          ShopPrintService.printA4(eventData.data.documentUrl);
        }
      );

      /**
       *
       * Listen for message to update printer state.
       * Depending on passed state:
       *  - it will change printer icon color
       *  - it will prevent money actions (payin, payout, cancel) if valid is set to false
       *
       * @event module:"7Shop.Print"."7S:Peripherals.UpdatePrinterState"
       * @type {Object}
       * @property {Object} data
       * @property {Boolean} data.valid - Either true or false.
       * @property {String} data.statusLevel - It will affect color of printer icon in bottom right corner.
       * Possible values: ok (green), error (red), warning (yellow), unknown (gray).
       * @property {String} data.errorMessage - If status is not valid ti will show this message to user.
       */
      $rootScope.$on(
        '7S:Peripherals.UpdatePrinterState',
        function (e, eventData) {
          PeripheralsSvc.updatePeripheralStatus('PRINTER', eventData.data);
        }
      );
    }
  };
}

export default PrintListeners;
