import './app';
import './betshop/betshopService';
import './calculator/calculatorComponent';
import './calculator/calculatorService';
import './company/companyService';
import './directives/isDesktopDirective';
import './directives/sAutoFocusDirective';
import './directives/isTouchDirective';
import './directives/sEqualsDirective';
import './gravity/gravityApplicationProvider';
import './gravity/gravityMenusProvider';
import './gravity/gravitySettingsProvider';
import './gravity/gravityFormsProvider';
import './gravity/gravityLayoutsProvider';
import './keyEvents/keyEventService';
import './overlay/overlayService';
import './overlay/overlayDirective';
import './providers/loginDataProvider';
import './router/routerConfigurationConstant';
import './services/configService';
import './services/deviceService';
import './services/environmentService';
import './services/generalModulesService';
import './services/metadataService';
import './services/playersService';
import './services/refreshTokenService';
import './services/stateAssemblerProvider';
import './services/switchViewService';
import './services/templateLoaderService';
import './services/voucherDataService';
import './services/workingTimeService';
import './services/scanEventService';
import './shortcuts/isShortcutActiveFilter';
import './shortcuts/shortcutService';
import './tickets_session/ticketsSessionService';
import './tickets_session/ticketsSessionStatusComponent';
import './filters/sevenMoneyOverrideFilter';
import './components/sGravityControlComponent';

export default '7Shop.Core';
