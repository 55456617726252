/* @ngInject */
function mneTaxInterceptor(companyService) {
  /**
   * @param {string} url
   * @returns {boolean}
   */
  const isValidUrl = (url) => {
    const baseUrl = process.env.MNE_V2_API.replace('{tenant}', companyService.data.uuid);
    return url.includes(baseUrl);
  };

  /**
   * @param {Object} request
   */
  const addApiKey = (request) => {
    const apiKey = process.env.MNE_V2_API_KEY;
    request.headers['X-Api-Key'] = apiKey;
  };

  return {
    request(requestConfig) {
      if (!isValidUrl(requestConfig.url)) {
        return requestConfig;
      }

      addApiKey(requestConfig);
      return requestConfig;
    }
  };
}

export default mneTaxInterceptor;
