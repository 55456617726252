/* @ngInject */
export default function (
  $translate,
  ShopPrintService,
  PrintListeners,
  PeripheralsSvc
) {
  PrintListeners.setPrinterListeners();
  PeripheralsSvc.register({
    id: 'PRINTER',
    validate: ShopPrintService.getPrinterStatus,
    valid: false,
    errorMessage: $translate.instant('settings.print_not_available'),
    statusLevel: 'unknown'
  });
}
