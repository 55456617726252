/**
 * @ngInject
 */
function DeviceManagementRun($window, DeviceManagementCoreService) {
  if ($window.DM?.Core) {
    DeviceManagementCoreService.core = $window.DM.Core;
  }
}

export default DeviceManagementRun;
