import { has, extend } from 'underscore';

(function () {
  /**
   * This is were we keep detected flags by different runners, be that android or node webkit.
   * ALso it is possible that some external data changes flag state, like data from CMS.
   *
   * @constructor
   */
  function ShopEnvironment() {
    var self = this;

    this.FLAGS = {
      INSTALL_WITH_CODE: 'INSTALL_WITH_CODE',
      ACTIVE_RUNTIME: 'ACTIVE_RUNTIME',
      NEED_INSTALLATION: 'NEED_INSTALLATION'
    };

    this.ENVS = {
      DEV: 'dev',
      STAGING: 'staging',
      PRODUCTION: 'production'
    };

    this.flagsState = {};

    this.urlFlags = {};

    this.env = '';

    this.baseDCUrl = '';

    this.runtimes = {
      ls: { // local storage
        dataLoader: 'localStorageLoader'
      },
      nar: { // nsoft application runtime
        dataLoader: 'narDeviceLoader'
      },
      dm: { // device management
        dataLoader: 'dmDeviceLoader'
      }
    };

    this.init = function () {
      var flags = [];
      var installationModeFlag = {};

      this.detectFlagsFromUrl();

      // if ACTIVE_RUNTIME is not set try to find default one
      if (!this.getFlagState(this.FLAGS.ACTIVE_RUNTIME)) {
        // by default we are in hosted env and we use local storage as source
        // of device data but if flag ar is passed then we will use WAR/NAR as device
        // data source

        installationModeFlag.id = this.FLAGS.ACTIVE_RUNTIME;
        installationModeFlag.active = true;
        installationModeFlag.value = !this.getUrlFlag('ar') ? this.runtimes.ls.dataLoader : this.runtimes.nar.dataLoader;

        flags.push(installationModeFlag);
      }

      this.setFlagsState(flags);
    };

    /**
     *
     * @param env
     */
    this.setEnv = function (env) {
      this.env = env;
    };

    this.getBaseDCUrl = function () {
      return this.baseDCUrl;
    };

    /**
     *
     * @param {Array.<{id: string, active:: boolean, value: string}>}flags
     */
    this.setFlagsState = function (flags) {
      flags.forEach(function (flag) {
        if (!has(self.flagsState, flag.id)) {
          self.flagsState[flag.id] = {};
        }

        extend(self.flagsState[flag.id], flag);
      });
    };

    /**
     *
     * @param flagId
     * @return {boolean}
     */
    this.isFlagActive = function (flagId) {
      return has(this.flagsState, flagId)
        && this.flagsState[flagId].active;
    };

    this.getFlagState = function (flag) {
      return this.flagsState[flag];
    };

    this.parseUrlFlags = function () {
      var str = document.location.search;
      if (typeof str !== 'string') {
        return {};
      }

      str = str.trim().replace(/^(\?|#|&)/, '');

      if (!str) {
        return {};
      }

      const result = str.split('&').reduce(function (ret, param) {
        var parts = param.replace(/\+/g, ' ').split('=');
        // Firefox (pre 40) decodes `%3D` to `=`
        // https://github.com/sindresorhus/query-string/pull/37
        var key = parts.shift();
        var val = parts.length > 0 ? parts.join('=') : undefined;

        key = decodeURIComponent(key);

        // missing `=` should be `null`:
        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
        val = val === undefined ? null : decodeURIComponent(val);
        // eslint-disable-next-line
        if (!ret.hasOwnProperty(key)) {
          ret[key] = val;
        } else if (Array.isArray(ret[key])) {
          ret[key].push(val);
        } else {
          ret[key] = [ret[key], val];
        }

        return ret;
      }, {});

      return result;
    };

    this.detectFlagsFromUrl = function () {
      var flags = [];
      var installationModeFlag = {};

      this.urlFlags = this.parseUrlFlags();

      if (this.getUrlFlag('installationMode')) {
        installationModeFlag.id = this.FLAGS.NEED_INSTALLATION;
        installationModeFlag.active = true;
        flags.push(installationModeFlag);
      }

      if (this.getUrlFlag('ar')) {
        flags.push({
          id: this.FLAGS.INSTALL_WITH_CODE,
          active: true
        });

        let urlFlag = this.getUrlFlag('ar');

        if (urlFlag === 'true') {
          urlFlag = 'nar';
        }

        flags.push({
          id: this.FLAGS.ACTIVE_RUNTIME,
          active: true,
          value: this.runtimes[urlFlag].dataLoader
        });

        if (this.getUrlFlag('profileUuid')) {
          // this is slave instance of shop, we will load device data
          // from another surce
          flags.push({
            id: this.FLAGS.ACTIVE_RUNTIME,
            active: true,
            value: 'sevenDeviceProfileLoader'
          });
        }
      }

      this.setFlagsState(flags);
    };

    this.getUrlFlag = function (flag) {
      return this.urlFlags[flag];
    };
  }

  window.ShopEnvironment = new ShopEnvironment();
})();
