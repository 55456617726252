import translate from 'angular-translate';
import '@nsftx/seven-ng-client-core/dist/seven-ng-client-core';
import '@nsftx/seven-ng-print-service/dist/ng-print-service';
import ngLib from '../../modules/ng_lib';
import PeripheralsModule from '..';
// eslint-disable-next-line import/no-cycle
import ShopModules from '../../modules';
import UserModule from '../../user';
import coreModule from '../../core';
import commonModule from '../../common';
import ShopPrint from './shopPrintService';
import listener from './printListenersService';
import sPrinterStatus from './sPrinterStatusComponent';
import configBlock from './configBlock';
import runBlock from './runBlock';

/**
 * @module "7Shop.Print"
 */
const modulName = angular.module('7Shop.Print', [
  translate,
  ngLib,
  'nabPrintService',
  'SevenClient.Config',
  'SevenClientCore',
  PeripheralsModule,
  commonModule,
  coreModule,
  ShopModules,
  UserModule
]).config(configBlock)
  .factory('ShopPrintService', ShopPrint)
  .service('PrintListeners', listener)
  .component('sPrinterStatus', sPrinterStatus)
  .run(runBlock).name;

export default modulName;
