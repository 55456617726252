import PubSub from 'pubsub-js';
import templateUrl from './calculator.html';
import templateTouchUrl from './touch/calculator.html';

(function () {
  angular.module('7Shop.Core')
    .component('sCalculator', {
      templateUrl: ['Touch', function (Touch) {
        return Touch.isTouch()
          ? templateTouchUrl : templateUrl;
      }],
      controller: sCalculatorCtrl,
      bindings: {
        showTicketsSession: '<?'
      }
    });

  /**
   *
   * @memberOf module:"7Shop.Core"
   * @class sCalculatorCtrl
   */
  function sCalculatorCtrl(
    $scope,
    $rootScope,
    GravitySettings,
    calculatorService,
    popupService,
    focusNext,
    TicketsSessions,
    googleAnalyticsService
  ) {
    var ctrl = this;
    var popUpShown = false;
    var openPopUp;
    var closePopUp;
    var pinCalculator;
    var unpinCalculator;
    var toggleCalculatorPopUp;
    var initializeCalculatorSession;

    openPopUp = function () {
      popupService.showPopup('calculator');
      // Focus first input field
      focusNext(98, true);
    };

    closePopUp = function () {
      popupService.closePopup('calculator');
    };

    pinCalculator = function () {
      calculatorService.setPinnedFlag(true);
    };

    unpinCalculator = function () {
      calculatorService.setPinnedFlag(false);
    };

    // Unpin calculator and show pop-up/Only close pop-up
    toggleCalculatorPopUp = function () {
      // Toggle pop-up flag
      popUpShown = !popUpShown;

      if (popUpShown) {
        unpinCalculator();
        openPopUp();
      } else {
        closePopUp();
      }
    };

    ctrl.focusMoneyInInput = () => {
      focusNext(98);
    };

    initializeCalculatorSession = function () {
      ctrl.calculator = calculatorService.calculator;
      ctrl.session = TicketsSessions.startNewOrGetActiveSession();
    };

    const getRestMoneyTranslation = () => {
      if (!ctrl.restMoneyAmount) {
        return 'calculator.rest';
      }

      if (ctrl.restMoneyAmount > 0) {
        return 'calculator_return_to_player';
      }

      return 'calculator_take_from_player';
    };

    const getRestMoneyColor = () => {
      /**
       * @type {{ missingMoney: string, extraMoney: string }}
       */
      const calculatorRestMoneyColor = GravitySettings.getModuleDataKeyValue(
        'calculator',
        'restMoneyColors'
      );

      if (!calculatorRestMoneyColor || !ctrl.restMoneyAmount) {
        return 'transparent';
      }

      if (ctrl.restMoneyAmount > 0) {
        return calculatorRestMoneyColor.extraMoney;
      }

      return calculatorRestMoneyColor.missingMoney;
    };

    ctrl.handleRestMoneyAmount = () => {
      ctrl.restMoneyAmount = ctrl.calculator.moneyIn + ctrl.session.payout - ctrl.session.payment;
      ctrl.restMoneyTranslation = getRestMoneyTranslation();
      ctrl.restMoneyColor = getRestMoneyColor();
    };

    ctrl.$onInit = function () {
      initializeCalculatorSession();
      ctrl.restMoneyAmount = 0;
      ctrl.restMoneyTranslation = '';
      ctrl.restMoneyColor = '';
      ctrl.isPinnedView = ctrl.calculator.pinned && !ctrl.showTicketsSession;
    };

    ctrl.toggleCalculator = function () {
      if (ctrl.calculator.pinned) {
        unpinCalculator();
      } else {
        pinCalculator();
      }

      closePopUp();
      googleAnalyticsService.trackEvent('Calculator', {
        event: ctrl.calculator.pinned ? 'Pin Calculator' : 'Unpin Calculator'
      });
    };
    ctrl.closePopUp = closePopUp;

    ctrl.resetCalculator = function () {
      googleAnalyticsService.trackEvent('Calculator', {
        event: 'Session Tickets'
      });

      TicketsSessions.endDirtySession();
      ctrl.session = TicketsSessions.startNewOrGetActiveSession();
      ctrl.calculator.moneyIn = 0;
      calculatorService.executePostResetHooks();
      /**
       * @event "7S:Calculator.SessionEnded"
       * @memberOf module:"7Shop.Core".sCalculatorCtrl
       */
      $rootScope.$emit('7S:Calculator.SessionEnded');
    };

    // Listen for key-press events
    const listenerToggle = PubSub.subscribe('7S:KeyboardEvent.calculator_toggle', toggleCalculatorPopUp);
    const listenerReset = PubSub.subscribe('7S:KeyboardEvent.calculator_reset', () => {
      ctrl.resetCalculator();
    });
    const moneyInInputFocusListener = PubSub.subscribe(
      '7S:KeyboardEvent.calculator_money_in',
      (e, { shortcut }) => {
        if (shortcut.active && calculatorService.isPinned()) {
          ctrl.focusMoneyInInput();
        }
      }
    );

    $scope.$on('popUp:close:calculator', function () {
      // popup is already in close process
      // so just update state
      popUpShown = false;
    });
    // check session state on popup show
    $scope.$on('popUp:show:calculator', function () {
      ctrl.session = TicketsSessions.startNewOrGetActiveSession();
    });

    const restMoneyAmountChangeWatcher = $scope.$watch(
      '[$ctrl.calculator.moneyIn, $ctrl.session]',
      () => {
        ctrl.handleRestMoneyAmount();
      },
      true
    );

    ctrl.$onDestroy = () => {
      PubSub.unsubscribe(listenerToggle);
      PubSub.unsubscribe(listenerReset);
      PubSub.unsubscribe(moneyInInputFocusListener);
      restMoneyAmountChangeWatcher();
    };
  }
})();
