/* @ngInject */
function DeviceManagementCoreService() {
  /**
   * @type {Core | null}
   */
  this.core = null;

  /**
   * Returns the core instance.
   *
   * @return {Core | null}
   */
  this.getCore = () => this.core;
}

export default DeviceManagementCoreService;
