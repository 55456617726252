/**
 *
 * @class sPrinterStatusCtrl
 * @memberOf module:"7Shop.Print"
 */
/* @ngInject */
function sPrinterStatusCtrl(ShopPrintService) {
  var ctrl = this;

  ctrl.$onInit = function () {
    ctrl.printServiceState = ShopPrintService.printServiceState;
  };
}

export default {
  controller: sPrinterStatusCtrl,
  template: '<i class="n-i n-i-printer"'
   + ' ng-class="\'code_\' + $ctrl.printServiceState.status.statusLevel"></i>'
};
